/**
 * HTML要素にクラス名を追加
 */
export class Initialize {

  /**
   * ユーザーエージェント
   */
  private readonly ua: string = navigator.userAgent;

  /**
   * クラス名
   */
  private className: string = '';

  /**
   * HTML要素にクラス名を追加
   */
  public setHtmlClass(): void {
    //JavaScript有効判定
    this.className += ' js-available';

    //Retina判定
    if (window.devicePixelRatio > 1) {
      this.className += ' retina' + Math.round(window.devicePixelRatio);
    }

    // UA判定
    if (this.ua.match(/iPhone|iPad|iPod/i)) {
      this.className += ' ios';
      if (this.ua.match(/iPad/i)) {
        this.className += ' ipad';
      }
    } else if (this.ua.match(/Windows/i)) {
      this.className += ' win';
      if (this.ua.match(/rv:11/i)) {
        this.className += ' ie ie11';
      }
    } else if (this.ua.match(/android/i)) {
      this.className += ' android';
    } else if (this.ua.match(/Macintosh/i)) {
      this.className += ' mac';
    }

    if (this.ua.match(/MSIE/i)) {
      this.className += ' ie';
      if (this.ua.match(/MSIE 10/i)) {
        this.className += ' ie10';
      } else if (this.ua.match(/MSIE 9/i)) {
        this.className += ' ie9';
      } else if (this.ua.match(/MSIE 8/i)) {
        this.className += ' ie8';
      } else if (this.ua.match(/MSIE 7/i)) {
        this.className += ' ie7';
      } else if (this.ua.match(/MSIE 6/i)) {
        this.className += ' ie6';
      }
    } else if (this.ua.match(/AppleWebkit/i) && this.ua.match(/Edge/i)) {
      this.className += ' edge';
    } else if (this.ua.match(/chrome/i)) {
      this.className += ' chrome';
    } else if (this.ua.match(/firefox/i)) {
      this.className += ' firefox';
    } else if (this.ua.match(/safari/i)) {
      this.className += ' safari';
    }

    // HTML要素にクラス名を追加
    document.getElementsByTagName('html')[0].className = this.className;
  }

}
