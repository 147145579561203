/**
 * モジュール読み込み
 */
import { Initialize } from './modules/Initialize';

/**
 * 実行クラス
 */
new (class Main {

  /**
   * コンストラクタ
   */
  constructor() {
    /**
     * HTML要素にクラス名を追加 (先頭に記述)
     */
    const initialize: Initialize = new Initialize();
    initialize.setHtmlClass();

    /**
     * object-fit polyfill
     */
    const objectFitImages: any = require('object-fit-images');
    objectFitImages();

    /**
     * スムーススクロール
     */
    const SmoothScroll: any = require('smooth-scroll');
    const smooth: any = new SmoothScroll('a[href*="#"]', {
      speed: 500,
      speedAsDuration: true,
      ignore: '.scroll-ignore',
      offset: (anchor: any, toggle: any): number => {
        return document.querySelector('.header')!.clientHeight;
      },
    });
  }

})();
